import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { connect } from "react-redux";
import nProgress from "nprogress";

import Layout from "layouts";

const FallBack = () => {
  useEffect(() => {
    nProgress.start();
    return () => {
      nProgress.done();
    };
  }, []);

  return null;
};

const routes = [
  // Auth Pages
  {
    path: "/login",
    Component: lazy(() => import("pages/auth/login")),
    exact: true,
  },
  {
    path: "/recover-password",
    Component: lazy(() => import("pages/auth/recover-password")),
    exact: true,
  },
  {
    path: "/reset-password",
    Component: lazy(() => import("pages/auth/reset-password")),
    exact: true,
  },
  {
    path: "/404",
    Component: lazy(() => import("pages/auth/404")),
    exact: true,
  },
  {
    path: "/500",
    Component: lazy(() => import("pages/auth/500")),
    exact: true,
  },
  // Dashboard
  {
    path: "/dashboard",
    Component: lazy(() => import("pages/dashboard")),
    exact: true,
  },
  {
    path: "/publications",
    Component: lazy(() => import("pages/publications")),
    exact: true,
  },
  {
    path: "/publications/create",
    Component: lazy(() => import("pages/publications/create-or-update")),
    exact: true,
  },
  {
    path: "/publications/update/:id",
    Component: lazy(() => import("pages/publications/create-or-update")),
    exact: true,
  },
  // {
  //   path: '/masters',
  //   Component: lazy(() => import('pages/masters')),
  //   exact: true,
  // },
  {
    path: "/classifications",
    Component: lazy(() => import("pages/classifications")),
    exact: true,
  },
  {
    path: "/masters/sections",
    Component: lazy(() => import("pages/masters/sections")),
    exact: true,
  },
  {
    path: "/masters/ad-types",
    Component: lazy(() => import("pages/masters/ad_types")),
    exact: true,
  },
  {
    path: "/masters/functional-types",
    Component: lazy(() => import("pages/masters/functional_types")),
    exact: true,
  },
  {
    path: "/masters/named-pages",
    Component: lazy(() => import("pages/masters/named_pages")),
    exact: true,
  },
  {
    path: "/masters/modules",
    Component: lazy(() => import("pages/masters/modules")),
    exact: true,
  },
  {
    path: "/masters/special-positions",
    Component: lazy(() => import("pages/masters/special_positions")),
    exact: true,
  },
  {
    path: "/masters/publication-issue-dates",
    Component: lazy(() => import("pages/masters/issue_dates")),
    exact: true,
  },
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
});

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === "none" ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/publications" />}
                    />
                    {/* <Route exact path="/">
                      <Landing />
                    </Route> */}
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={FallBack}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
