import React from "react";
import style from "./style.module.scss";

const TopBar = () => {
  return (
    <div className={`${style.topbar} top-bar-sec`}>
      <div className="mr-auto" />
      {/* <div className="mr-4">
        <div className="top-block-item">Last Logged In Time : 2021-01-01 19:20:12 </div>
      </div> */}
    </div>
  );
};

export default TopBar;
