import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { history } from 'index'
import {
  load,
  login,
  recoverPassword,
  resetPassword,
} from 'api/user'
import store from 'store'
import RootAction from 'redux/actions'
import actions from './actions'
import { userStateData } from './reducers'

function* HANDLE_USER_TOKEN(accessToken) {
  store.set(`user.accessToken`, accessToken)
  console.log('access_token', accessToken)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken,
      email: null,
      username: null,
      canResendEmail: false,
      resendEmail: false,
    },
  })
  yield history.push('/')
}

export function* LOGIN({ payload }) {
  // payload.device_id = Array(15).fill(null).map(() => Math.random().toString(36).substr(2)).join('').slice(0, 48)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: false,
    },
  })
  if (response) {
    console.log('response.data', response.data);
    const { token: accessToken } = response.data.data
    yield call(HANDLE_USER_TOKEN, accessToken)
    // yield call(LOAD_CURRENT_ACCOUNT)
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  if (!store.get(`user.accessToken`)) return
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(load)

  if (response) {
    const { user } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: user
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* RECOVER_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: true,
    },
  })
  const { response, error } = yield call(recoverPassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: false,
    },
  })
  if (response) {
    yield history.push('/')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        recover: errors,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response, error } = yield call(resetPassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: false,
    },
  })
  if (response) {
    const { access_token: accessToken } = response.data
    yield call(HANDLE_USER_TOKEN, accessToken)
    yield call(LOAD_CURRENT_ACCOUNT)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        reset: errors,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(actions.RECOVER_PASSWORD, RECOVER_PASSWORD),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),

    takeEvery(actions.HANDLE_USER_TOKEN, HANDLE_USER_TOKEN),
    // LOAD_CURRENT_ACCOUNT(),
  ])
}
