import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'title.timesheet_reports': 'Timesheet Reports',
  'title.timesheets': 'Timesheets',
  'action.previewWebsite': 'Preview Website',
  'action.add': 'Add',
  'action.addCoupon': 'Add Coupon',
  'action.addCustomImage': 'Add Custom Image',
  'action.addLocation': 'Add Location',
  'action.addTax': 'Add Tax',
  'action.addUser': 'Add User',
  'action.cancel': 'Cancel',
  'action.change': 'Change',
  'action.changeColor': 'Change Color',
  'action.choose': 'Choose',
  'action.confirm': 'Confirm',
  'action.connect': 'Connect',
  'action.customerPortalBgImg': 'Customer Portal Background Image',
  'action.download': 'Download',
  'action.downloadTemplate': 'Download Template',
  'action.edit': 'Edit',
  'action.export': 'Export',
  'action.forgotPassword': 'Forgot password?',
  'action.giftCardPromotion': 'Gift Card Promotion',
  'action.gotoSignIn': 'Go to Sign in',
  'action.import': 'Import',
  'action.issue': 'Issue',
  'action.issueGiftCard': 'Issue Gift Card',
  'action.logout': 'Logout',
  'action.next': 'Next',
  'action.manage': 'Manage',
  'action.no': 'No',
  'action.recoverPassword': 'Recover my password',
  'action.redeem': 'Redeem',
  'action.reload': 'Reload',
  'action.remove': 'Remove',
  'action.resend': 'Resend',
  'action.resendEmail': 'Resend Email',
  'action.resetPassword': 'Reset my password',
  'action.save': 'Save',
  'action.search': 'Search',
  'action.select': 'Select',
  'action.send': 'Send',
  'action.signIn': 'Sign in',
  'action.signUp': 'Sign up',
  'action.update': 'Update',
  'action.upload': 'Upload',
  'action.view': 'View',
  'action.viewDetails': 'View Details',
  'action.viewReceipt': 'View Receipt',
  'action.viewStore': 'View Store',
  'action.yes': 'Yes',
  'action.generateReport': 'Generate report',
  'form.placeholder.popupurl': 'Enter the promotion popup url',

  'text.custommessage.closedorder': 'No closed orders to show',
  'text.reportType': 'Order Type',
  'text.reportCount': 'Count',
  'text.reportSales': 'Sales',
  'text.reportTax': 'Taxes',
  'text.reportCreditDebit': 'Credit/Debit',
  'text.reportTip': 'Tip',
  'text.reportcash': 'Cash',
  'action.exporttopdf': 'Export To PDF',
  'action.exporttoexcel': 'Export To Excel',

  'text.due': 'Due:',
  'text.placed': 'Placed:',
  'text.dollar': '$',
  'text.cancel': 'Cancel',
  'text.confirm': 'Confirm',
  'text.complete': 'Complete',
  'text.readyForPickup': 'Ready for Pickup',
  'text.ondelivery': 'On Delivery',
  'text.refundOrder': 'Refund order',
  'text.subTotal': 'SubTotal',
  'text.discount': 'Discount',
  'text.tax': 'Tax',
  'text.total': 'Total',
  'text.tip': 'Tip',
  'text.changeEstimatedTime': 'Change estimated time?',
  'text.confirmTimeChange': 'Confirm Time Change',

  'text.true': 'True',
  'text.false': 'False',
  'form.label.aboutUsImage': 'About Us Image',
  'form.label.dateRange': 'Date Range',
  'text.option.today': 'Today',
  'text.option.yesterday': 'Yesterday',
  'text.option.thisWeek': 'This Week',
  'text.option.lastWeek': 'Last Week',
  'text.option.thisMonth': 'This Month',
  'text.option.lastMonth': 'Last Month',
  'text.option.customDateRange': 'Custom Date Range',

  'text.upload': 'Upload',
  'form.itemForm.cardTitle': 'Basic Item Info',
  'form.itemName.required': 'Item Name is required',
  'form.itemName.minimum.required': 'Please add atleast 3 characters for item name',
  'form.itemName.maximum.required': 'Maximum 20 characters allowed in item name',
  'form.placeholder.itemName': 'Item Title',
  'form.label.itemName': 'Item Name',
  'form.placeholder.description': 'Item Desciption',
  'form.label.category': 'Category',
  'form.itemCategory.required': 'Item Category is required',
  'form.placeholder.category': 'Select a category',
  'form.label.itemprice': 'Item Price',
  'form.itemPrice.required': 'Item Price is required',
  'form.placeholder.itemPrice': '123.99',
  'form.label.tax': 'Set custom taxes for this item',
  'form.label.taxValue': 'Item Taxes',
  'form.customTax.required': 'Custom Tax is Required',
  'form.placeholder.customTax': '13',
  'form.label.internalDisplayName': 'Internal Display Name',
  'form.internalDisplayName.required': 'Internal Display is required',
  'form.label.mostPopular': 'Most Popular',
  'text.popularItems': 'Is this among your popular items?',
  'form.cardTitle.photo': 'Photo',
  'form.label.photo': 'Upload high quality image for the item',
  'form.cardTitle.modifierGroups': 'Modifier Groups',
  'form.placeholder.modifierGroups': 'Select a modifier group',
  'form.cardTitle.itemAvailability': 'Item Availability',
  'text.hours': 'Hours',
  'text.sameAsStoreHours': 'Same as Store hours',
  'text.specifySpecialHours': 'Specify special hours for this item',
  'text.sunday': 'S',
  'text.monday': 'M',
  'text.tuesday': 'T',
  'text.wednesday': 'W',
  'text.thursday': 'T',
  'text.friday': 'F',
  'text.saturday': 'S',
  'text.day.sunday': 'Sunday',
  'text.day.monday': 'Monday',
  'text.day.tuesday': 'Tuesday',
  'text.day.wednesday': 'Wednesday',
  'text.day.thursday': 'Thursday',
  'text.day.friday': 'Friday',
  'text.day.saturday': 'Saturday',
  'text.item': 'Item',
  'text.title.removeCategory': 'Remove Category',
  'text.title.removeMenuItem': 'Remove Menu Item',
  'text.drawerTitle.addItem': 'Add Item',
  'text.drawerTitle.updateItem': 'Update Item',
  'text.description.removeCategoryModal':
    'Deleting this category will also delete all the items inside it.',
  'text.description.removeMenuItemModal': 'Delete this menu item.',

  'desc.backgroundImage': 'Background Image',
  'desc.barcode': 'Barcode',
  'desc.icon': 'Icon',

  'empty.image': 'No images.',
  'empty.staffs': 'No users has been added.',

  'form.error.deliveryfees': 'Delivery fees should not be more than 3 digits or less than $999',
  'form.error.orderValueTxt': 'Order value should not be more than 3 digits or less than $999',
  'form.error.orderValueCustomTxt': 'Order value should be more than delivery min. amount',
  'form.error.delivery_minimum_amount':
    'Delivery Minimum should not be more than 4 digits or less than $9999',
  'form.error.amount.required': 'Please input the amount',
  'form.error.businessAddress.required': 'Please input the business address',
  'form.error.businessAddress.max': 'Business address must be up to 180 characters',
  'form.error.businessDays.required': 'Please select at least 1 day',
  'form.error.businessName.required': 'Please input the business name',
  'form.error.businessName.max': 'Business name must be up to 64 characters',
  'form.error.code.max': 'Code must be up to 20 characters',
  'form.error.code.min': 'Code must be min 3 characters',
  'form.error.coupon.minPurchaseValue':
    'Minimum Purchase Value should be higher than the Coupon Value',
  'form.error.disallow.specialChars': 'Special characters are not allowed',
  'form.error.contactPhone.invalid': 'Invalid contact phone number',
  'form.error.contactPhone.alt.invalid': 'Invalid alt. contact phone number',
  'form.error.email.required': 'Please input the email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 64 characters',
  'form.error.empty.select': 'No matches found.',
  'form.error.firstName.required': 'Please input the first name',
  'form.error.firstName.max': 'First name must be up to 64 characters',
  'form.error.lastName.required': 'Please input the last name',
  'form.error.lastName.max': 'Last name must be up to 64 characters',
  'form.error.locationAddress.required': 'Please input the location address',
  'form.error.locationAddress.max': 'Business address must be up to 180 characters',
  'form.error.loginPin.length': 'Must be a 4 digit pin',
  'form.error.password.required': 'Please input the password',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.max': 'Password must be up to 32 characters',
  'form.error.password.match': "Passwords doesn't match",
  'form.error.phone.invalid': 'Invalid phone number',
  'form.error.required': 'Required',

  'form.helper.coupon.channel': 'Coupon will be valid only at the selected channels',
  'form.helper.coupon.minPurchaseValue':
    'Apply coupon for Gift Card value above or equal to Min. purchase value',
  'form.helper.coupon.orderType': 'Coupon will be valid only for the selected order types',
  'form.helper.recipientEmail': 'Digital card will be sent to this email',
  'form.helper.senderEmail': 'A copy of the digital card will be sent to this email',

  'form.label.bannerImage': 'Banner Image',
  'form.label.firstbannerheading': 'Banner Heading Text',
  'form.placeholder.firstbannerheading': 'Enter the Banner Heading text',
  'form.label.firstbannerheadingcolor': 'Banner Heading Text Color',
  'form.label.firstbannersubheading': 'Banner Sub Heading Text',
  'form.placeholder.firstbannersubheading': 'Enter the Banner Sub Heading Text',
  'form.label.firstbannersubheadingcolor': 'Banner Sub Heading Text Color',
  'form.label.calltoactiontext': 'Call to Action Button Text',
  'form.placeholder.calltoactiontext': 'Enter the call to Action Button Text',
  'form.label.calltoactiontextcolor': 'Call to Action Button Background Color',
  'form.label.calltoactionurl': 'Call to Action URL',
  'form.placeholder.calltoactionurl': 'Enter the call to Action URL',

  'form.label.aboutUsSubBanner': 'Sub Banner Image',
  'form.label.gallerySubBannerImage': 'Sub Banner Image',
  'form.label.contactUsSubBannerImage': 'Sub Banner Image',
  'form.label.aboutUsHeading': 'Heading',
  'form.placeholder.aboutUsHeading': 'Enter the Heading',
  'form.label.aboutUsSubHeading': 'Sub Heading',
  'form.placeholder.aboutUsSubHeading': 'Enter the Sub Heading',
  'form.label.aboutUsPageDescription': 'Description',
  'form.placeholder.aboutUsPageDescription': 'Enter the Description',

  'form.label.menuSubBannerImage': 'Sub Banner Image',
  'form.placeholder.customerdomain': 'www.dvv.io',

  'form.label.websiteTitle': 'Website Page Title',
  'form.placeholder.websiteTitle': 'Enter the Page Title',

  'form.label.websiteDescription': 'Website Page Description',
  'form.placeholder.websiteDescription': 'Enter the Page Description',

  'form.placeholder.Script': 'Enter the Script Code',

  'form.label.websiteKeywords': 'Website Page Keywords',
  'form.placeholder.websiteKeywords': 'Enter the Page Keywords',

  'form.label.secondbannerheading': 'Heading Text',
  'form.placeholder.secondbannerheading': 'Enter the Headind',
  'form.label.secondbannersubheading': 'Sub Heading Text',
  'form.placeholder.secondbannersubheading': 'Enter the Sub Heading',

  'form.label.thirdbannerheading': 'Heading Text',
  'form.placeholder.thirdbannerheading': 'Enter the Heading',
  'form.label.thirdbannersubheading': 'Sub Heading Text',
  'form.placeholder.thirdbannersubheading': 'Enter the Sub Heading',

  'form.label.googleAnalytics': 'Google Analytics code',
  'form.placeholder.googleAnalytics': 'Enter the Google Analytics Code',

  'form.label.facebookPixel': 'Facebook Pixel ID',
  'form.placeholder.facebookPixel': 'Enter the facebook Pixel ID',

  'form.label.googlePlacesId': 'Google Places ID',
  'form.placeholder.googlePlacesId': 'Enter the Google Places ID',

  'form.label.facebook': 'Facebook URL',
  'form.placeholder.facebook': 'Enter the Facebook profile url',

  'form.label.twitter': 'Twitter URL',
  'form.placeholder.twitter': 'Enter the Twitter profile url',

  'form.label.linkedin': 'Linkedin URL',
  'form.placeholder.linkedin': 'Enter the Linkedin profile url',

  'form.label.instagram': 'Instagram URL',
  'form.placeholder.instagram': 'Enter the Instagram profile url',

  'form.label.youtube': 'Youtube URL',
  'form.placeholder.youtube': 'Enter the Youtube page url',

  'form.label.yelp': 'Yelp URL',
  'form.placeholder.yelp': 'Enter the Yelp url',

  'form.label.galleryimage': 'Upload Image',
  'action.uploadImage': 'Upload Image',
  'text.empty': '    ',

  'form.label.active': 'Active',
  'form.label.address': 'Address',
  'form.label.allowManageCoupons': 'Allow to Manage Coupons?',
  'form.label.allowManageCustomers': 'Allow to Manage Customers?',
  'form.label.allowManageDeliveryRadius': 'Allow to Manage Delivery Radius?',
  'form.label.allowManageIntegrations': 'Allow to Manage Integrations?',
  'form.label.allowManageMenu': 'Allow to Manage Menu?',
  'form.label.allowManageOnlineOrderSettings': 'Allow to Manage Online Store Setup?',
  'form.label.allowManageOrders': 'Allow to Manage Orders?',
  'form.label.allowManageUsers': 'Allow to Manage Users?',
  'form.label.allowRedeemCard': 'Allow to Redeem Card?',
  'form.label.allowReloadCard': 'Allow to Reload Card?',
  'form.label.allowRunReport': 'Allow to Run Reports?',
  'form.label.altPhone': 'Alt. Phone Number',
  'form.label.appId': 'App ID',
  'form.label.backgroundColor': 'Background Color',
  'form.label.backgroundImage': 'Background Image',
  'form.label.businessAddress': 'Business Address',
  'form.label.businessName': 'Business Name',
  'form.label.businessType': 'Business Type',
  'form.label.channel': 'Channel',
  'form.label.email': 'Email',
  'form.label.email.current': 'Current Email',
  'form.label.email.new': 'New Email',
  'form.label.from': 'From',
  'form.label.city': 'City',
  'form.label.cloneMenuLocation': 'Menu Clone Location',
  'form.label.code': 'Code',
  'form.label.contactName': 'Owner Name',
  'form.label.contactFirstName': 'First Name',
  'form.label.contactLastName': 'Last Name',
  'form.label.contactPhone': 'Owner Phone Number',
  'form.label.contactPhone.alt': 'Alt. Phone Number',
  'form.label.coupon.autoApplyCheckout': 'Apply coupon automatically at checkout?',
  'form.label.coupon.code': 'Coupon Code',
  'form.label.coupon.minPurchaseValue': 'Mininum Purchase Value',
  'form.label.coupon.type': 'Coupon Type',
  'form.label.coupon.value': 'Coupon Value',
  'form.label.country': 'Country',
  'form.label.cardNumber': 'Card Number',
  'form.label.creditCard': 'Credit Card',
  'form.label.cvv': 'CVV',
  'form.label.description': 'Description',
  'form.label.deliveryDate': 'Delivery Date',
  'form.label.employeeId': 'Employee ID',
  'form.label.expiry': 'Expiry Date',
  'form.label.firstName': 'First Name',
  'form.label.giftCardBalance': 'Gift Card Balance',
  'form.label.giftCardCopy': 'Send a copy of Gift Card?',
  'form.label.giftCardNumber': 'Gift Card Number',
  'form.label.giftCardType': 'Gift Card Type',
  'form.label.image': 'Image',
  'form.label.language': 'Language',
  'form.label.language.preferred': 'Preferred Language',
  'form.label.lastName': 'Last Name',
  'form.label.location': 'Location',
  'form.label.locationAddress': 'Location Address',
  'form.label.loginPin': 'Login Pin',
  'form.label.maxUsageLimit': 'Maximum Usage Limit per Customer',
  'form.label.merchantId': 'Merchant ID',
  'form.label.message': 'Message',
  'form.label.minPurchaseValue': 'Minimum Purchase Value',
  'form.label.name': 'Name',
  'form.label.orderType': 'Order Type',
  'form.label.password': 'Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'form.label.payment': 'Payment',
  'form.label.paymentMethod': 'Payment Method',
  'form.label.paymentBy': 'Payment By',
  'form.label.percent': 'Percent',
  'form.label.phone': 'Phone Number',
  'form.label.pin': 'Pin',
  'form.label.postalCode': 'Postal Code',
  'form.label.province': 'Province',
  'form.label.redeemValue': 'Redeem Value',
  'form.label.rePassword': 'Re-Password',
  'form.label.reloadValue': 'Reload Value',
  'form.label.retypeLoginPin': 'Retype Login Pin',
  'form.label.role': 'Role',
  'form.label.stateProvince': 'State/Province',
  'form.label.storeName': 'Store Name',
  'form.label.storeType': 'Store Type',
  'form.label.textColor': 'Text Color',
  'form.label.themeColor': 'Theme Color',
  'form.label.timezone': 'Timezone',
  'form.label.to': 'To',
  'form.label.transactionType': 'Transaction Type',
  'form.label.user': 'User',
  'form.label.userId': 'User ID',
  'form.label.zipPostalCode': 'Zip/Postal Code',
  'form.label.website': 'Website',
  'form.placeholder.website': 'Enter the website url',

  'form.placeholder.printername': 'Printer Name',
  'form.placeholder.address': 'Enter the address',
  'form.placeholder.altPhone': 'Enter the alt. phone number',
  'form.placeholder.businessCity': 'City',
  'form.placeholder.businessCountry': 'Country',
  'form.placeholder.businessName': 'Enter the business name',
  'form.placeholder.businessPostalCode': 'Postal code',
  'form.placeholder.businessProvince': 'Province',
  'form.placeholder.businessType': 'Select the business type',
  'form.placeholder.amount': '0.00',
  'form.placeholder.cardName': 'Name on card',
  'form.placeholder.cardNumber': '4242 4242 4242 4242',
  'form.placeholder.city': 'Select the city',
  'form.placeholder.cloneMenuLocation': 'Select the location',
  'form.placeholder.contactName': 'Enter the contact name',
  'form.placeholder.contactFirstName': 'Enter the first name',
  'form.placeholder.contactLastName': 'Enter the last name',
  'form.placeholder.contactPhone': 'Enter the contact phone number',
  'form.placeholder.contactPhone.alt': 'Enter the alt. phone number',
  'form.placeholder.coupon.code': 'Coupon code',
  'form.placeholder.coupon.description': 'Short description about the coupon',
  'form.placeholder.coupon.value': 'Coupon value',
  'form.placeholder.country': 'Select the country',
  'form.placeholder.customerEmail': 'Enter the customer email',
  'form.placeholder.customerName': 'Enter the customer name',
  'form.placeholder.customerPhone': 'Enter the customer phone number',
  'form.placeholder.email': 'Enter the email',
  'form.placeholder.email.current': 'Enter the current email address',
  'form.placeholder.email.new': 'Enter the new email address',
  'form.placeholder.expirationMonth': 'MM',
  'form.placeholder.expirationYear': 'YY',
  'form.placeholder.firstName': 'Enter the first name',
  'form.placeholder.giftCardBalance': 'Enter the gift card balance',
  'form.placeholder.giftCardNumber': 'xxxxxxxxxxxxxxxx',
  'form.placeholder.language': 'Select the language',
  'form.placeholder.lastName': 'Enter the last name',
  'form.placeholder.location.name': 'Enter the location name',
  'form.placeholder.location': 'Select the location',
  'form.placeholder.loginPin': 'Enter the 4-digit login pin',
  'form.placeholder.message': 'Enter a personalised message',
  'form.placeholder.recipientEmail': "Enter the recipient's email",
  'form.placeholder.recipientName': "Enter the recipient's full name",
  'form.placeholder.recipientPhone': "Enter the recipient's phone number",
  'form.placeholder.password': 'Enter the password',
  'form.placeholder.password.account': 'Your account password',
  'form.placeholder.password.confirm': 'Enter the new password again',
  'form.placeholder.password.new': 'Enter the new password',
  'form.placeholder.percent': '0',
  'form.placeholder.phone': 'Enter the phone number',
  'form.placeholder.postalCode': 'Enter the postal code',
  'form.placeholder.province': 'Select the province',
  'form.placeholder.rePassword': 'Enter the password again',
  'form.placeholder.retypeLoginPin': 'Enter the 4-digit login pin again',
  'form.placeholder.role': 'Select the role',
  'form.placeholder.search': 'Search..',
  'form.placeholder.search.cardNumber': 'Search by card number..',
  'form.placeholder.search.coupon': 'Search by code, description..',
  'form.placeholder.search.customer': 'Search by name, email..',
  'form.placeholder.search.staff': 'Search by name, email..',
  'form.placeholder.search.tax': 'Search Tax',
  'form.placeholder.senderEmail': "Enter the sender's email",
  'form.placeholder.senderName': "Enter the sender's full name",
  'form.placeholder.senderPhone': "Enter the sender's phone number",
  'form.placeholder.stateProvince': 'Select the state/province',
  'form.placeholder.storeName': 'Enter the store name',
  'form.placeholder.storeType': 'Enter the store type',
  'form.placeholder.streetAddress': 'Street address',
  'form.placeholder.colorCode': 'Enter Color Code',
  'form.placeholder.tax.name': 'Enter the tax name',
  'form.placeholder.timezone': 'Select the timezone',
  'form.placeholder.transactionType': 'Select the timezone',
  'form.placeholder.user': 'Select the user',
  'form.placeholder.users': 'Select the users for this location',
  'form.placeholder.taxes': 'Select the taxes for this location',
  'form.placeholder.zipPostalCode': 'Enter the zip/postal code',

  'form.select.all': 'All',
  'form.select.card': 'Card',
  'form.select.cash': 'Cash',
  'form.select.delivery': 'Delivery',
  'form.select.employee': 'Employee',
  'form.select.english': 'English',
  'form.select.french': 'French',
  'form.select.inStore': 'In-Store',
  'form.select.manager': 'Manager',
  'form.select.onlineStore': 'Online Store',
  'form.select.owner': 'Owner',
  'form.select.pickup': 'Pickup',
  'form.select.times': '{count, plural, =1{1 time} other{# times}}',
  'form.select.unlimited': 'Unlimited',

  'helper.dragDropImage': 'Click or drag image to this area to upload',

  // common links
  'link.termsService': 'Terms of Service',
  'link.privacyPolicy': 'Privacy Policy',

  'note.logoSize':
    'Image dimension should be 230px × 87px in JPG or PNG format with the size less than 1MB.',

  'progress.resendingEmail': 'Resending email..',
  'progress.uploading': 'Uploading.. please wait..',
  'progress.verifyingCode': 'Verifying the code..',

  'tab.giftEasyGallery': 'Gift Easy Gallery',
  'tab.upload': 'Upload',
  'tab.yourLibrary': 'Your Library',

  'text.accountInfo': 'Account Info',
  'text.action': 'Action',
  'text.active': 'Active',
  'text.activity': 'Activity',
  'text.addCoupon': 'Add Coupon',
  'text.addLocation': 'Add Location',
  'text.addUser': 'Add User',
  'text.addTax': 'Add Tax',
  'text.address': 'Address',
  'text.alreadyHaveAccount': 'Already have an account?',
  'text.and.spaced': ' and ',
  'text.amount': 'Amount',
  'text.applyLocations': 'Apply to Locations',
  'text.assignLocations': 'Assign Locations',
  'text.authNumber': 'Auth. Number',
  'text.businessProfile.title': 'Business Profile',
  'text.businessProfile.description':
    'Tell us about your business so we can help you set it up to accept payments.',
  'text.byUser': 'By {name} - {location}',
  'text.card': 'Card',
  'text.changeEmail': 'Change Email',
  'text.chooseImage': 'Choose Image',
  'text.city': 'City',
  'text.cloneMenu': 'Clone Menu',
  'text.clover': 'Clover',
  'text.cloverIntegrationInfo': 'Clover Integration Info',
  'text.code': 'Coupon Code',
  'text.confirmEmail.title': 'Confirm Email',
  'text.confirmEmail.description':
    "We've sent a 6-digit verification code to {email}.{break}The code expires in 10m, so please enter it soon.",
  'text.confirmPassword': 'Confirm Password',
  'text.contactDetails': 'Contact Details',
  'text.ownerDetails': 'Owner Information',
  'text.converge': 'Converge',
  'text.convergeIntegrationInfo': 'Converge Integration Info',
  'text.copyright': 'Copyright © 2021, {appName} Inc. All rights reserved.',
  'text.couponDetails': 'Coupon Details',
  'text.couponList': 'Coupon List',
  'text.createAccount': 'Create your account',
  'text.creditCard': 'Credit Card',
  'text.customerEmail': 'Customer Email',
  'text.customerList': 'Customer List',
  'text.customerName': 'Customer Name',
  'text.customerPhone': 'Customer Phone',
  'text.date': 'Date',
  'text.dateTime': 'Date & Time',
  'text.description': 'Description',
  'text.usage': 'Usage',
  'text.deliveryService': 'Delivery Service',
  'text.didntReceiveCode': "Didn't receive the code?",
  'text.digitalCard': 'Digital Card',
  'text.dontHaveAccount': "Don't have an account?",
  'text.editCustomer': 'Edit Customer',
  'text.email': 'Email',
  'text.expiry': 'Expiry Date',
  'text.exportPhysicalCards': 'Export Physical Cards',
  'text.flatRate': 'Flat Rate',
  'text.giftCardBalance': 'Gift Card Balance',
  'text.giftCardDesign': 'Gift Card Design',
  'text.giftCardDetails': 'Gift Card Details',
  'text.giftCardList': 'Gift Card List',
  'text.giftCardNumber': 'Gift Card Number',
  'text.giftCardReport': 'Gift Card Report',
  'text.giftCardStoreDesign': 'Gift Card Store Design',
  'text.giftIssued': 'Gift Issued',
  'text.giftRedeemed': 'Gift Redeemed',
  'text.giftUnredeemed': 'Gift Unredeemed',
  'text.importExternalCards.title': 'Import External Gift Cards',
  'text.importExternalCards.description':
    'Download the excel template from the link below and upload the same for importing the gift cards from other platforms.',
  'text.inactive': 'Inactive',
  'text.integration.clover.change.title': 'Change Account',
  'text.integration.clover.change.description': 'Connect with a different clover merchant account.',
  'text.issueGiftCard': 'Issue Gift Card',
  'text.location': 'Location',
  'text.locations': 'Locations',
  'text.locationDetails': 'Location Details',
  'text.locationList': 'Location List',
  'text.logo': 'Logo',
  'text.logout.content': 'Are you sure you want to logout?',
  'text.name': 'Name',
  'text.notConnected': 'Not Connected',
  'text.operatingHours.delivery': 'Set different operating hours for delivery',
  'text.operatingHours.pickup': 'Set different operating hours for pickup',
  'text.operatingHours.sameAsOpening': '(Same as store hours)',
  'text.onlineStore': 'Online Store',
  'text.ownerProfile.title': 'Owner Profile',
  'text.ownerProfile.description':
    'Tell us about the owner of the business to start your application.',
  'text.paidAmount': 'Paid Amount',
  'text.paymentAccountInfo': 'Payment Account Info',
  'text.paymentDetails': 'Payment Details',
  'text.paymentMethod': 'Payment Method',
  'text.percent': 'Percent',
  'text.percentage': 'Percentage',
  'text.permissions': 'Permissions',
  'text.permissions.location': 'Location Permissions',
  'text.physicalCard': 'Physical Card',
  'text.physicalCardDetails':
    'You have purchased {totalCount} physical gift cards and there are {unusedCount} unused cards. Do you want to download the unused cards?',
  'text.pickupService': 'Pickup Service',
  'text.postalCode': 'Postal Code',
  'text.preferences': 'Preferences',
  'text.preview.digitalCard': 'Digital Card Preview',
  'text.recipientDetails': 'Recipient Details',
  'text.recipientName': 'Recipient Name',
  'text.redeemedAmount': 'Redeemed Amount',
  'text.redeemGiftCard': 'Redeem Gift Card',
  'text.register.agreement': 'By signing up, you agree to the',
  'text.reloadGiftCard': 'Reload Gift Card',
  'text.removePickupHour.description': 'Do you want to remove All Pickup Hours',
  'text.removeDeliveryHour.description': 'Do you want to remove All Delivery Hours',
  'text.removeCoupon': 'Remove {code}',
  'text.removeCoupon.description': 'Are you sure you want to remove this coupon?',
  'text.removeHour.description': 'Are you sure you want to remove?',
  'text.removeTax': 'Remove {name}',
  'text.removeTax.description': 'Are you sure you want to remove this tax?',
  'text.removeUser': 'Remove {name}',
  'text.removeUser.description': 'Are you sure you want to remove this user?',
  'text.resendGiftCard': 'Resend Gift Card',
  'text.role': 'Role',
  'text.senderDetails': 'Sender Details',
  'text.serialNo': 'S.No.',
  'text.signIn.title': 'Login',
  'text.status': 'Status',
  'text.stepper.businessProfile': 'Business Profile',
  'text.stepper.subscription': 'Subscription',
  'text.storeDetails': 'Store Information',
  'text.taxList': 'Tax List',
  'text.updateCoupon': 'Update Coupon',
  'text.updateLocation': 'Update Location',
  'text.updateTax': 'Update Tax',
  'text.updateUser': 'Update User',
  'text.user': 'User',
  'text.userDetails': 'User Details',
  'text.userList': 'User List',
  'text.users': 'Users',
  'text.taxes': 'Taxes',
  'text.taxDetails': 'Tax Details',
  'text.transactionHistory': 'Transaction History',
  'text.transactionType': 'Transaction Type',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'text.value': 'Value',
  'text.couponValue': 'Coupon Value',
  'text.slno': 'S.No',
  'text.invoiceNumber': 'Invoice Number',
  'text.invoiceDate': 'Invoice Date',
  'text.invoiceStatus': 'Invoice Status',

  'title.website.Websitethemeolor': 'Website Theme Color',
  'title.website.websiteManager': 'Website Manager',
  'title.website.websiteSetup': 'Website Setup',
  'title.website.contactUsSection': 'Contact US Section',
  'title.website.themeDesign': 'Theme and Design',
  'title.website.bannerSection': 'Banner Section',
  'title.website.aboutusSection': 'Aboutus Section',
  'title.website.menuSection': 'Menu Section',
  'title.website.GallerySection': 'Gallery Section',
  'title.website.seo': 'SEO',
  'title.website.analytics': 'Analytics',
  'title.website.socialMedia': 'Social Media',
  'title.website.seosocialmedia': 'SEO & Social Media',
  'title.website.gallery': 'Gallery',
  'title.website.promotion': 'Website Popup',
  'form.label.websiteThemeColor': 'Website Theme Color',
  'title.orderhistory': 'Order History',
  'title.business': 'Business',
  'title.confirmEmail': 'Confirm Email',
  'title.login': 'Login',
  'title.manage.deliveryRadius': 'Delivery Zones',
  'title.manage.coupon': 'Manage Coupons',
  'title.manage.customer': 'Manage Customers',
  'title.manage.giftCard': 'Manage Gift Cards',
  'title.manage.location': 'Manage Locations',
  'title.manage.tax': 'Manage Taxes',
  'title.manage.user': 'Manage Users',
  'title.manage.employee': 'Manage Employees',
  'action.addEmployee': 'Add Employee',
  'text.addEmployee': 'Add Employee',
  'text.updateEmployee': 'Update Employee',
  'text.Name': 'Name',
  'text.LoginPin': 'Login Pin',
  'action.inactive': 'In Active',
  'action.active': 'Active',
  'form.label.Status': 'Status',
  'text.removeEmployee': 'Remove Employee',
  'text.removeEmployee.description': 'Do you want to remove this employee?.',
  'text.EmployeeDetails': 'Employee Details',
  'form.placeholder.employee.name': 'Enter the employee name',
  'form.placeholder.employee.loginpin': 'Enter the login pin',
  'form.error.loginpin.length': 'Enter the login pin',
  'form.employeename.required': 'Name Required',
  'form.loginPin.required': 'Login Pin Required',
  'form.placeholder.search.employee': 'Search by employee name',
  'title.website.ManageDomain': 'Manage Domain',
  'title.manage.OnlineStoreDesign': 'Branding',
  'title.manage.onlineOrrderSetup': 'Online Store Setup',
  'title.recoverPassword': 'Recover Password',
  'title.resetPassword': 'Reset Password',
  'title.register': 'Register',
  'title.settings.giftCard': 'Gift Card Settings',
  'title.setup.integrations': 'Integrations',
  'title.setup.billing': 'Billing',
  'title.setup.website_widget': 'Website Widget',
  'title.setup.store': 'Store Setup',
  'title.summary.redeem': 'Redeemed Summary',
  'title.summary.sales': 'Sales Summary',
  'title.setup.reports': 'Reports',
  'text.orderList': 'Closed Orders',
  'form.label.yourWebsiteLink': 'Your website link',
  'form.label.websiteDomainUrl': 'Website Domain URL',
  'action.emailToWebMaster': 'Email to my webmaster',
  'form.placeholder.domainurl': 'https://www.example.com',
  'form.changeColor': 'Choose Color',
  'text.instructions': 'Instructions',
  'text.instructionsDesc':
    'You can forward your domain to the above url or you can connect your domain by changing the DNS below - ',

  'text.orderNumber': 'Order#',
  'text.customerInfo': 'Customer Name',
  'text.orderRequested': 'Order Requested',
  'text.orderType': 'Order Type',
  'text.orderTotal': 'Order Total',
  'text.closedOrder.search': 'Search by order number or customer name',

  'text.addZone': 'Add Delivery Zone',
  'text.deliveryRate': 'Delivery Fee',
  'text.deliveryMinimumAmount': 'Delivery Minimum',
  'text.mapShape': 'Zone Shape',
  'text.circle': 'Circle',
  'text.square': 'Square',
  'text.custom': 'Custom',
  'text.zoneName': 'Zone Name',
  'form.error.deliveryzone.deliveryFees': 'Delivery Fees should be higher than zero',
  'form.error.deliveryzone.deliveryMinimumAmount': 'Delivery Minimum should be higher than zero',
  'form.placeholder.deliveryFees': '0.00',
  'form.placeholder.orderValue': 'Order Value',
  'form.placeholder.zoneName': 'Zone Name',
  'form.placeholder.deliveryMinimumAmount': '0.00',

  'text.menuAppearance': 'Menu Appearance',
  'text.ordernotifications': 'Order Notifications',
  'text.siteAppearance': 'Online Store Appearance',
  'text.pickupOptions': 'Pickup Options',
  'text.deliveryOptions': 'Delivery Options',
  'text.ageVerification': 'Age Verification',
  'text.miscellaneous': 'Order Printer Options',
  'text.allergyWarning': 'Allergy Warning',
  'text.paymentOptions': 'Payment Options',
  'text.openingHours': 'Store Hours',
  'text.deliveryHours': 'Delivery Hours',
  'text.pickUpService': 'Pickup Service',
  'text.pickUpHours': 'Pickup Hours',
  'text.sameasbusinesshours': '(Same as store hours)',
  'text.sameoptiontext': '  Set different operating hours for pickup',
  'text.sameoptiontexttwo': '  Set different operating hours for delivery',

  'text.onlineorderingstoredesign': 'Online Ordering Store Design',
  'text.backgroundImage': 'Background Image',
  'text.cardOnline': 'Card(Online)',
  'text.payatcounter': 'Card(Pay at Counter)',
  'text.cash': 'Cash',
  'text.paybycard': 'Card(Pay on Delivery)',
  'text.paydeliverycash': 'Pay on delivery by Cash',
  'text.paydeliverycard': 'Pay on delivery by Card',

  'form.label.displaymenupicture':
    'Do you want to display menu pictures on your online ordering page?',
  'form.label.displayallergyingredients': 'Do you want to display allergy ingredients?',
  'form.label.displaymenupreferences': 'Do you want to display menu preferences?',
  'form.label.displaycaloriecount': 'Do you want to display menu calorie count?',
  'form.label.websitetheme': 'Kindly choose the website theme color?',
  'form.label.enabledelivery': 'Do you want to enable delivery for your online orders?',
  'form.label.neworderemailalert': 'Do you want to receive email alerts for orders?',
  'form.label.notify_emailid': 'Enter your email address',
  'form.label.newordercallalert': 'Do you want to receive a call alert for new orders?',
  'form.label.notify_mobile_number': 'Enter your phone number',
  'form.label.pickuporderpayments': 'What payment options do you to accept for pickup order?',
  'form.label.deliveryorderpayments': 'What payment options do you to accept for delivery order?',
  'form.label.averagedeliverytime': 'What is your average Delivery time?',
  'form.label.showtip': 'Do you want to show tip option?',
  'form.label.agerequired': 'What is the minimum age required to place an order?',
  'form.label.age_verification': 'Do you want to enable age verification?',
  'form.label.age_verification_info': 'Age verification message to the customers',
  'form.label.allergy_warning': 'Do you want to notify your customers about allergy warning?',
  'form.label.allergy_description': 'Allergy warning message to the customers',
  'form.label.acceptpickuporders': 'Do you accept pickup orders on your online ordering page?',
  'form.label.acceptdeliveryorders': 'Do you accept delivery orders on your online ordering page?',
  'form.label.acceptpickupasap': 'Do you want to accept ASAP pickup orders?',
  'form.label.acceptdeliveryasap': 'Do you want to accept ASAP delivery orders?',
  'form.label.acceptpickupscheduled': 'Do you want to accept scheduled pickup orders?',
  'form.label.acceptdeliveryscheduled': 'Do you want to accept scheduled delivery orders?',
  'form.label.averagepickuptime': 'What is your average pickup time?',
  'form.label.acceptorder': 'I want to accept',
  'form.label.minutes': 'minutes',
  'form.label.years': 'Years',
  'form.error.pickupminorder.min': 'Order must be more than 1',
  'form.error.deliveryorder.min': 'Order must be more than 1',
  'form.error.pickupinterval.min': 'Must be more than 1 minute',
  'form.error.pickuporderminimumtimeinadvance.min': 'Must be more than 1 minute',
  'form.error.deliverytime.min': 'Must be more than 1 minute',
  'form.error.averagedeliverytime.min': 'Must be more than 1 minute',
  'form.error.deliveryinterval.min': 'Must be more than 1 minute',
  'form.error.orderdaysinadvance.min': 'Must be more than 1 day',
  'form.error.deliveryorderdaysinadvance.min': 'Must be more than 1 day',
  'form.error.averagepickuptime.min': 'Must be more than 1 minute',
  'form.error.deliveryorderminimumtimeinadvance.min': 'must be more than 1 minute',
  'form.error.minimumagereq.min': 'Minimum age required is more than 18 years',
  'form.label.Pickupintervallist': 'pickup orders',
  'form.label.every': ' every',
  'form.label.Deliveryintervallist': 'delivery orders',
  'form.label.minorderinadvance_one': 'I want my clients to order atleast',
  'form.label.minorderinadvance_two': 'minutes',
  'form.label.minorderinadvance_three': 'in advance',
  'form.label.orderdaysinadvance_one': 'I want my clients to order no more than',
  'form.label.orderdaysinadvance_two': ' days',
  'form.label.orderdaysinadvance_three': 'in advance',
  'form.label.ordertimefornextday_one': 'I want my clients to order before',
  'form.label.ordertimefornextday_two': '    for next day orders',
  'form.label.miscellaneous.printer': 'Do you want to print customer receipt copy?',
  'form.placeholder.minimumorder': '2',
  'form.placeholder.Pickupintervallist': '15',
  'form.placeholder.deliveryintervallist': '15',
  'form.placeholder.orderdaysinadvance': '7',
  'form.placeholder.averagepickuptime': '90',
  'form.placeholder.averagedeliverytime': '90',
  'form.placeholder.age_verification_info': 'Enter your age verification message to your customers',
  'form.placeholder.allergy_description': 'Enter your allergy warning message to your customers',
  'form.label.print_customer_copy': 'Order Printer Connected',
  'form.label.noprintername': 'No order printer connected',
  'form.label.printScheduledOrders': 'I want to print the scheduled orders',
  'form.label.printScheduledOrders_two': 'before the order requested time',
  'form.label.neworderTone': 'New order notification tone',
  'form.label.callalert': 'Do you want to receive call alerts whenever an order is not confirmed?',
  'form.label.printcopy': 'Select the number of customer receipt copies to print',
  'form.label.accepttipforpickuporder': 'Do you want to accept tip for pickup orders?',
  'form.label.accepttipfordeliveryorder': 'Do you want to accept tip for delivery orders?',
  'form.label.notify.orderconfirm': 'Notify after',

  'form.placeholder.printScheduledOrders': 'Select minutes to print the schedules orders',
  'form.placeholder.neworderTone': 'Select your default new order tone',

  'form.placeholder.notify.mobile': '(855) 558-2345',
  'form.placeholder.notify.emailid': 'hello@familybistro.ca',
  'form.error.start_time.required': 'Please select the start time',
  'form.error.end_time.required': 'Please select the end time',

  'form.placeholder.pickuporderminimumtimeinadvance': '30',
  'form.placeholder.deliveryorderminimumtimeinadvance': '30',
  'form.placeholder.printcustomercopy': 'Select a printer',
  'form.placeholder.printcopy': 'Select your choice',
  'form.placeholder.agerequired': '18',
  'form.placeholder.opening_hours_start_time': 'Start Time',
  'form.placeholder.opening_hours_end_time': 'End Time',
  'form.placeholder.pickup_hours_start_time': 'Start Time',
  'form.placeholder.pickup_hours_end_time': 'End Time',
  'form.placeholder.delivery_hours_start_time': 'Start Time',
  'form.placeholder.delivery_hours_end_time': 'End Time',
  'form.placeholder.set_time': 'Set Time',

  'message.copyClipboard': 'Copied to clipboard',
  'action.copyStoreUrl': 'Copy Store URL',
  'action.verifyDomain': 'Verify Domain Connection',

  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.business': 'Business',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
