import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getPublications } from 'services/publications'
import actions from './actions'

export function* SET_PUBLICATION_DATA() {

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true
    }
  })

  const { response } = yield call(getPublications);

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        publications: response.data.data
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SET_PUBLICATION_DATA, SET_PUBLICATION_DATA)
  ])
}
