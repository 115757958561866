import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getUserMenuData } from 'services/menu'
import actions from './actions'

export function* SET_DATA() {
  const menuData = yield call(getUserMenuData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SET_DATA, SET_DATA),
    SET_DATA(), // run once on app load to fetch menu data
  ])
}
