import apiClient from "services/axios";

const BASE_URL = "/user/api";

export async function getSections(payload) {
  return apiClient
    .get(`${BASE_URL}/sections`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getAdTypes(payload) {
  return apiClient
    .get(`${BASE_URL}/ad_types`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getNamedPages(payload) {
  return apiClient
    .get(`${BASE_URL}/named_pages`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getFunctionalTypes(payload) {
  return apiClient
    .get(`${BASE_URL}/functional_types`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getModules(payload) {
  return apiClient
    .get(`${BASE_URL}/modules`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getClassificationCategories(payload) {
  return apiClient
    .get(`${BASE_URL}/classification_categories`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getClassifications(payload) {
  return apiClient
    .get(`${BASE_URL}/classifications`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getSpecialPositions(payload) {
  return apiClient
    .get(`${BASE_URL}/special_positions`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getEnums(payload) {
  return apiClient
    .get(`${BASE_URL}/enums`, payload)
    .then((response) => ({ response }))
    .catch(() => false);
}

export async function getPublicationIssueDates(payload) {
  return apiClient
    .get(
      `${BASE_URL}/publication-issuedates/${payload?.payload?.pubId || ""}`,
      payload
    )
    .then((response) => ({ response }))
    .catch(() => false);
}

export default getSections;
