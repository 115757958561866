import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAdTypes,
  getClassificationCategories,
  getClassifications,
  getEnums,
  getFunctionalTypes,
  getModules,
  getNamedPages,
  getSections,
  getSpecialPositions,
  getPublicationIssueDates,
} from "services/masters";
import actions from "./actions";

export function* SET_MASTER_DATA({ payload }) {
  let response = false;
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: true,
      loading: !(
        payload.type === "functional_types" ||
        payload.type === "special_positions" ||
        payload.type === "issue_dates" ||
        payload.type === "classification_categories"
      ),
      cLoading:
        payload.type === "functional_types" ||
        payload.type === "special_positions" ||
        payload.type === "issue_dates" ||
        payload.type === "classification_categories",
    },
  });

  if (payload && payload.type === "sections") {
    const { response: resp } = yield call(getSections);
    response = resp;
  }
  if (payload && payload.type === "ad_types") {
    payload.type = "adTypes";
    const { response: resp } = yield call(getAdTypes);
    response = resp;
  }
  if (payload && payload.type === "functional_types") {
    payload.type = "functionalTypes";
    const { response: resp } = yield call(getFunctionalTypes);
    response = resp;
  }
  if (payload && payload.type === "named_pages") {
    payload.type = "namedPages";
    const { response: resp } = yield call(getNamedPages);
    response = resp;
  }
  if (payload && payload.type === "modules") {
    const { response: resp } = yield call(getModules);
    response = resp;
  }
  if (payload && payload.type === "classification_categories") {
    payload.type = "categories";
    const { response: resp } = yield call(getClassificationCategories);
    response = resp;
  }
  if (payload && payload.type === "classifications") {
    const { response: resp } = yield call(getClassifications);
    response = resp;
  }
  if (payload && payload.type === "special_positions") {
    payload.type = "specialPositions";
    const { response: resp } = yield call(getSpecialPositions);
    response = resp;
  }

  if (payload && payload.type === "issue_dates") {
    payload.type = "issueDates";
    const { response: resp } = yield call(getPublicationIssueDates, {
      payload,
    });
    response = resp;
  }

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        [payload.type]: response.data.data,
      },
    });
  }

  const { response: resp } = yield call(getEnums);

  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: false,
      loading: false,
      cLoading: false,
      enums: resp ? resp.data : {},
    },
  });
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SET_MASTER_DATA, SET_MASTER_DATA)]);
}
