import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import settings from './settings/reducers'
import menu from './menu/reducers'
import masters from './masters/reducers'
import publications from './publications/reducers'
import RootAction from './actions'

export default (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    masters,
    publications
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
