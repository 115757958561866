import axios from "axios";
import { v4 as uuidV4 } from "uuid";
import store from "store";
import { notification } from "antd";
import UserAction from "redux/user/actions";
import { store as reduxStore } from "index";

let clientId = localStorage.getItem("client-id");
if (!clientId) {
  const uniqueId = uuidV4();
  localStorage.setItem("client-id", uniqueId);
  clientId = uniqueId;
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://dvv-print.fcrm.effy.works",
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-Device-Type": "Merchant Portal",
      "X-Client-ID": clientId,
      "X-App-Version": "2.0.0",
    },
  },
});

apiClient.interceptors.request.use((request) => {
  request.params = request.params || {};
  const accessToken = store.get("user.accessToken");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    if (response?.data?.message != null) {
      const { message } = response.data;
      notification.success({
        message,
      });
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      reduxStore.dispatch({
        type: UserAction.CLEAR_USER,
      });
    } else if (error?.response?.data != null) {
      const { message } = error.response.data;
      notification.error({
        message,
      });
    }
    throw error;
  }
);

export default apiClient;
