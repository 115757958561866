import { all } from 'redux-saga/effects'
import settings from './settings/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import masters from './masters/sagas'
import publications from './publications/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    settings(),
    menu(),
    masters(),
    publications()
  ])
}
