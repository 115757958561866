export async function getUserMenuData() {
  return [
    // {
    //   title: 'Dashboard',
    //   key: 'dashboard',
    //   icon: 'fe fe-home',
    //   url: '/dashboard',
    // },
    {
      title: "Publications",
      key: "publications",
      icon: "fa fa-columns",
      url: "/publications",
    },
    {
      title: "Classifications",
      key: "classifications",
      icon: "fe fe-book",
      url: "/classifications",
    },
    {
      title: "Masters",
      key: "masters",
      icon: "fe fe-server",
      url: "/masters",
      children: [
        {
          title: "Sections",
          key: "sections",
          name: "sections",
          url: "/masters/sections",
        },
        {
          title: "Modules",
          key: "modules",
          name: "modules",
          url: "/masters/modules",
        },
        {
          title: "Ad Types",
          key: "ad_types",
          name: "ad_types",
          url: "/masters/ad-types",
        },
        {
          title: "Functional Types",
          key: "functional_types",
          name: "functional_types",
          url: "/masters/functional-types",
        },
        {
          title: "Named Pages",
          key: "named_pages",
          name: "named_pages",
          url: "/masters/named-pages",
        },

        {
          title: "Special Positions",
          key: "special_positions",
          name: "special_positions",
          url: "/masters/special-positions",
        },
        {
          title: "Issue Dates",
          key: "issue_dates",
          name: "issue_dates",
          url: "/masters/publication-issue-dates",
        },
      ],
    },
  ];
}

export default getUserMenuData;
