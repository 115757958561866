import React from "react";
// import { FormattedMessage } from 'react-intl'
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Avatar, Modal } from "antd";
// import { Link } from 'react-router-dom'
import store from "store";
import styles from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({
  isMenuCollapsed: settings.isMenuCollapsed,
});

const { confirm } = Modal;

// const ProfileMenu = ({ dispatch, user, isMenuCollapsed }) => {
const ProfileMenu = ({ isMenuCollapsed }) => {
  // console.log(dispatch, user)
  const onLogout = (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to logout?",
      onOk: () => {
        store.clearAll();
        window.location.reload();
        // return logout().then(() => {
        //   dispatch({
        //     type: actions.LOGOUT,
        //   })
        //   return false
        // })
      },
      okText: "Yes, Go ahead",
      cancelText: "No",
    });
  };

  const menu = (
    <Menu selectable={false} style={{ padding: "10px" }}>
      {/* <Menu.Item style={{ paddingBottom: '12px' }}>
        <Link to="/change-password">
          <i className="fe fe-user mr-2" />
          Change Password
        </Link>
      </Menu.Item> */}
      <Menu.Item>
        <a href="#" onClick={onLogout}>
          <i className="fe fe-log-out mr-2" />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["hover"]} placement="topCenter">
      <div
        className={`${styles.dropdown} ${isMenuCollapsed ? styles.pr0 : ""}`}
      >
        <Avatar
          className={styles.avatar}
          shape="circle"
          size="large"
          icon={<UserOutlined />}
        />
        <span
          className={[isMenuCollapsed ? "hide" : "", "show-mobile"].join(" ")}
          style={{ paddingLeft: "12px" }}
        >
          Super admin
        </span>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
