import actions from "./actions";

const initialState = {
  sections: [],
  classifications: [],
  categories: [],
  adTypes: [],
  functionalTypes: [],
  modules: [],
  namedPages: [],
  specialPositions: [],
  issueDates: [],
  enums: {},
};

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
