import React, { useEffect, useState } from "react";
import Lottie from 'react-lottie-loader';
import loaderWithBG from './plento_logo_background.json';
import loaderWithoutBG from './plento_logo_transparent.json';

import style from './style.module.scss'

function LottieLoader({ transparent = true, offsetTop = 0, full = false, styles = {}, bgOpacity = 1 }) {

  const [layout, setLayout] = useState({
    height: "100%",
    width: "100%",
  })

  useEffect(() => {
    setLayout({
      width: window.innerWidth - (256 + 50),
      height: window.innerHeight - offsetTop,
    })
    window.addEventListener('resize', () => {
      setLayout({
        width: window.innerWidth - (256 + 50),
        height: window.innerHeight - offsetTop,
      })
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div style={!full ? { ...styles, background: `rgba(255,255,255,${bgOpacity})` } : { height: layout.height, width: layout.width, ...styles, background: `rgba(255,255,255,${bgOpacity})` }} className={`${style.lottieLoaderBlock}`}>
      <div className={style.lottieLoader}>
        <Lottie animationData={transparent ? loaderWithoutBG : loaderWithBG} />
      </div>
    </div>
  );
}

export default LottieLoader;
