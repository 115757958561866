import apiClient from 'services/axios'

const BASE_URL = '/user/api'

export async function getPublications(payload) {
  return apiClient
    .get(`${BASE_URL}/publications`, payload)
    .then(response => ({ response }))
    .catch(() => false)
}

export default getPublications